
/* eslint-disable no-console */
/* eslint-disable no-debugger */
import Vue from "vue";
import store from "@/store";
import versionService from "@/services/versionService";
import { AppVersion } from "../../../shared/constants";
import firebase, { db } from "../../../shared/plugins/firebase-app";
import {
  Communication,
  CommunicationSchedule,
  Form,
  FormUsedElements,
  Publish,
  Session,
  SessionVisit,
  Setting,
} from "../../../shared/models";
import IdGenerator from "../../../shared/extensions/IdGenerator";
import SettingService from "../../../shared/services/SettingService";

export default Vue.extend({
  data() {
    return {
      loading: false,
      error: "",
      status: "",
    };
  },
  async mounted() {
    try {
      this.status = "Migrating...";
      this.loading = true;
      const databaseVersion = await versionService.getDatabaseVersion();
      if (databaseVersion !== AppVersion) {
        this.status = "Updating the database...<br/>Check the browser console for logs.";
        await this.migrateToVersion41();
        this.status = "Setting database version...";
        await versionService.setDatabaseVersion(AppVersion);
        const returnUrl = this.$route.query["returnUrl"];
        if (returnUrl) {
          this.status = "Redirecting...";
          try {
            setTimeout(async () => {
              await this.$router.push(returnUrl.toString());
            }, 1000);
          } catch (error) {
            this.error = "Error while redirecting.";
          }
        } else {
          this.status = "Done.";
        }
      } else {
        this.status = "The database is up to date.";
      }
    } catch (error: any) {
      console.error(error);
      this.error = error.message;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async migrateToVersion4() {
      var batch = db.batch();
      const saveFormChildren = async (doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) => {
        const form = doc.data() as Form;
        const collection = db.doc(doc.ref.path).collection("Forms");
        const c = await collection.where("companyId", "==", this.companyId).get();
        for (const childDoc of c.docs) {
          const child = childDoc.data() as Form;
          const f: Form = { ...child, parentKey: form.key };
          console.log("Saving Form " + childDoc.ref.path);
          batch.set(db.collection("Forms").doc(f.id), f);
          await saveFormChildren(childDoc);
        }
        await this.deleteCollection(batch, collection, "Forms");
      };
      const saveSessionVisits = async (
        sessionId: string,
        visitKey: string,
        doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        formIds: string[]
      ) => {
        const collection = db.doc(doc.ref.path).collection("Children");
        const c = await collection.where("companyId", "==", this.companyId).get();
        for (const childDoc of c.docs) {
          const child = childDoc.data() as SessionVisit;
          const previousFormIds = [...formIds];
          formIds.push(child.id);

          if (visitKey == "") {
            visitKey = child.id;
          } else {
            const lastIndexOfLink = visitKey.lastIndexOf("link:");
            const lastVisitKey = lastIndexOfLink >= 0 ? visitKey.substring(lastIndexOfLink + "link:".length) : visitKey;
            const lastVisitKeyArray = lastVisitKey.split("/");
            lastVisitKeyArray.push(child.id);
            const formPath = `/Forms/${lastVisitKeyArray.join("/Forms/")}`;
            let visitFormExist = false;
            try {
              const visitForm = await db.doc(formPath).get();
              visitFormExist = visitForm && visitForm.exists;
            } catch (error) {
              //
            }
            if (visitFormExist) {
              visitKey += `/${child.id}`;
            } else {
              visitKey += `/link:${child.id}`;
            }
          }
          delete (child as any).key;
          const s: SessionVisit = {
            ...child,
            formId: child.id,
            id: IdGenerator.newId(),
            sessionId: sessionId,
            visitKey: visitKey,
            previousFormIds: previousFormIds.join("/"),
            formIds: formIds.join("/"),
            companyId: this.companyId,
          };
          console.log("Saving Session " + childDoc.ref.path);
          batch.set(db.collection("SessionVisits").doc(s.id), s);
          await saveSessionVisits(sessionId, visitKey, childDoc, formIds);
        }
        await this.deleteCollection(batch, collection, "Children");
      };
      // Update Sessions
      const sessionDocs = await db.collection("Sessions").where("companyId", "==", this.companyId).get();
      for (const doc of sessionDocs.docs) {
        await saveSessionVisits(doc.id, "", doc, []);
      }
      await batch.commit();
      batch = db.batch();
      // Update Forms
      const formDocs = await db.collection("Forms").where("companyId", "==", this.companyId).get();
      for (const doc of formDocs.docs) {
        batch.set(
          db.doc(doc.ref.path),
          {
            parentKey: "",
          },
          { merge: true }
        );
        await saveFormChildren(doc);
      }
      await batch.commit();
      batch = db.batch();
      // Update Communications
      const communications = await db.collection("Communications").where("companyId", "==", this.companyId).get();
      for (const communicationDoc of communications.docs) {
        const communication = communicationDoc.data() as Communication;
        const formKeyArray = (communication as any).formKey ? ((communication as any).formKey as string).split("/") : null;
        const formKeyToSendArray = (communication as any).formKeyToSend
          ? ((communication as any).formKeyToSend as string).split("/")
          : null;
        console.log("Update Communication " + communicationDoc.ref.path);
        batch.set(
          db.doc(communicationDoc.ref.path),
          {
            formKey: firebase.firestore.FieldValue.delete(),
            formId: formKeyArray ? formKeyArray[formKeyArray.length - 1] : null,
            formKeyToSend: firebase.firestore.FieldValue.delete(),
            formIdToSend: formKeyToSendArray ? formKeyToSendArray[formKeyToSendArray.length - 1] : null,
          },
          { merge: true }
        );
      }
      await batch.commit();
      batch = db.batch();
      // Update Communications Schedules
      const communicationSchedules = await db.collection("CommunicationSchedules").where("companyId", "==", this.companyId).get();
      for (const communicationScheduleDoc of communicationSchedules.docs) {
        const communicationSchedule = communicationScheduleDoc.data() as CommunicationSchedule;
        const formKeyArray = (communicationSchedule as any).formKey ? ((communicationSchedule as any).formKey as string).split("/") : null;
        const communicationFormKeyArray = (communicationSchedule as any).communicationFormKey
          ? ((communicationSchedule as any).communicationFormKey as string).split("/")
          : null;
        const sessionFormKeyArray = (communicationSchedule as any).sessionFormKey
          ? ((communicationSchedule as any).sessionFormKey as string).split("/")
          : null;
        console.log("Update Communication Schedule " + communicationScheduleDoc.ref.path);
        batch.set(
          db.doc(communicationScheduleDoc.ref.path),
          {
            formKey: firebase.firestore.FieldValue.delete(),
            formId: formKeyArray ? formKeyArray[formKeyArray.length - 1] : null,
            communicationFormKey: firebase.firestore.FieldValue.delete(),
            communicationFormId: communicationFormKeyArray ? communicationFormKeyArray[communicationFormKeyArray.length - 1] : null,
            sessionFormKey: firebase.firestore.FieldValue.delete(),
            sessionFormId: sessionFormKeyArray ? sessionFormKeyArray[sessionFormKeyArray.length - 1] : null,
          },
          { merge: true }
        );
      }
      await batch.commit();
      batch = db.batch();
      // Update Form Used Elements
      const usedElements = await db.collection("UsedElements").where("companyId", "==", this.companyId).get();
      for (const usedElementDoc of usedElements.docs) {
        const usedElement = usedElementDoc.data() as FormUsedElements;
        const formIds: string[] = [];
        for (const x of (usedElement as any).formKeys as string[]) {
          if (x && x.indexOf("/") >= 0) {
            const arr = x.split("/");
            formIds.push(arr[arr.length - 1]);
          }
        }
        console.log("Update Used Element " + usedElementDoc.ref.path);
        batch.set(
          db.doc(usedElementDoc.ref.path),
          {
            formKeys: firebase.firestore.FieldValue.delete(),
            formIds,
          },
          { merge: true }
        );
      }
      await batch.commit();
      batch = db.batch();
      // Update Link Aliases
      const linkAliases = await db.collection("LinkAliases").where("companyId", "==", this.companyId).get();
      for (const linkAliasDoc of linkAliases.docs) {
        console.log("Update Link Aliase " + linkAliasDoc.ref.path);
        batch.set(
          db.doc(linkAliasDoc.ref.path),
          {
            formKey: firebase.firestore.FieldValue.delete(),
          },
          { merge: true }
        );
      }
      await batch.commit();
      batch = db.batch();
      // Update Publishes
      const publishes = await db.collection("Publishes").where("companyId", "==", this.companyId).get();
      for (const publishDoc of publishes.docs) {
        const publish = publishDoc.data() as Publish;
        const formKeyArray = ((publish as any).formKey as string).split("/");
        console.log("Update Publish " + publishDoc.ref.path);
        batch.set(
          db.doc(publishDoc.ref.path),
          {
            formKey: firebase.firestore.FieldValue.delete(),
            formId: formKeyArray[formKeyArray.length - 1],
          },
          { merge: true }
        );
      }
      await batch.commit();
      batch = db.batch();
    },
    async migrateToVersion41() {
      var batch = db.batch();
      batch.set(
        db.collection("Settings").doc(SettingService.DefaultSettingId),
        { id: SettingService.DefaultSettingId, jwtClientId: "", jwtClientSecret: SettingService.DefaultClientSecret } as Setting,
        { merge: false }
      );
      const sessionDocs = await db.collection("Sessions").where("companyId", "==", this.companyId).get();
      for (const sessionDoc of sessionDocs.docs) {
        const session = sessionDoc.data() as Session;
        if (!session.lastModifiedDateTime) {
          batch.set(
            db.doc(sessionDoc.ref.path),
            { lastModifiedDateTime: session.startDatetime || firebase.firestore.Timestamp.now() },
            { merge: true }
          );
        }
      }
      await batch.commit();
    },
    async deleteCollection(
      batch: firebase.firestore.WriteBatch,
      collection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>,
      subCollectionName: string
    ) {
      const c = await collection.where("companyId", "==", this.companyId).get();
      for (const doc of c.docs) {
        if (subCollectionName) {
          const subCollection = db.doc(doc.ref.path).collection(subCollectionName);
          await this.deleteCollection(batch, subCollection, subCollectionName);
        }
        console.log("Deleting " + doc.ref.path);
        batch.delete(doc.ref);
      }
    },
  },
  computed: {
    companyId(): string {
      return store.getters.companyId;
    },
  },
});
